import React from 'react';

const And: React.FC = ( ) => {
    return (
        <abbr className='amp' title='and'>&amp;</abbr>
    );
}

// Exports
export default And;
